img{
    height: 80%;
    width: 82%;
    margin: 1em;
    border-radius:0.5em;
    vertical-align: middle;
}

.project-card{
    margin-top:5em;
    margin-bottom:5em;
    margin-left:10em;
    margin-right:10em;
    padding-left:2em;
    padding-right: 2em;
    background-color: #deeff5;
    border-radius: 0.5em;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
}

.project-image{
    align-items:center;
}

.project-title{
    padding: 1em;
}

.project-description{
    text-align: left;
    font-size: 1.5em;
}

.project-content{
    margin-top: 5em;
    margin-bottom: 5em;
}

h3{
    margin-bottom: 1.2em;
}

button{
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 1.5em;
    color:#08629a;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

button:hover{
    background-color: white;
    border-radius: 0.5em;
    box-shadow: 0.2em 0.2em 0.5em rgba(0,0,0,0.2)
}


@media all and (max-width: 480px) {
    img {
      width: 3rem;
    }
  }